import React from 'react'
import { graphql } from 'gatsby'

import Article from '../components/Article/Article'
import SEO from '../components/SEO/Seo'

const articleTemplate = ( { data: { strapi: { article } }, location } ) => {
	
	const { articleContent, articleLeftIllustration, articleRightIllustration, articleSubTitle, articleTitle, seoDescription } = article

	return (
		<>
			<SEO
				title={ `${ articleTitle } | Article` }
				description={ seoDescription }
				pathname={ location.pathname } />

			<Article 
				title={ articleTitle } 
				subTitle={ articleSubTitle } 
				content={ articleContent } 
				leftIllustration={ articleLeftIllustration } 
				rightIllustration={ articleRightIllustration } />

		</>
	)
}

export default articleTemplate


export const query = graphql`
	query articleQuery($id: ID!) {
		strapi {
			article( id: $id ) {
				articleContent
				articleLeftIllustration
				articleRightIllustration
				articleSubTitle
				articleTitle
				seoDescription
			}
		}
	}
`