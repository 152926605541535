import React from 'react'
import ReactMarkdown from 'react-markdown'

import Illustrations from '../Illustrations/Illustrations'

export default function Article( props ) {

	//ReactMarkdown accepts custom renderers
	const renderers = {
		//This custom renderer changes how images are rendered
		//we use it to constrain the max width of an image to its container
		image: ({ alt, src, title, }) => ( React.createElement("img", { alt: alt, src: src, title: title, className: "article__article-image" })),
	};

	return (
		<div className={`article wrapper ${ props.isMakeover ? 'article--makeover' : '' }`}>
			<div className="article__wrapper">

				<div className="article__header">{ props.title }</div>

				{ props.subTitle &&
					<div className="article__sub-header">{ props.subTitle }</div>
				}

				{ props.children }

				<ReactMarkdown
					className="article__article-body"
					escapeHtml={ false }
					source={ props.content }
					renderers={ renderers } />

				{ props.leftIllustration &&
					<Illustrations class="article__illustration article__illustration--left" illustration={ props.leftIllustration } />
				}

				{ props.rightIllustration &&
					<Illustrations class="article__illustration article__illustration--right" illustration={ props.rightIllustration } />
				}
			</div>
		</div>
	)
}